<template>
    <div class="container">
        <div class="banner">
            <img :src="info.banner">
        </div>
        <div class="brand">
            <div class="navtitle">
                <div class="title">品牌介绍</div>
                <div class="sub_title">BRAND INTRODUCTION</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="main">
                <p>
                    {{ info.intro }}
                </p>
                <div class="main_center">
                    <div class="logo_item" v-for="(item,index) in info.logos" :key="index">
                        <img v-lazy="item">
                    </div>
                </div>
            </div>
        </div>
        <div style="position: relative;">
            <div class="advantage">
                <div class="navtitle">
                    <div class="title">核心优势</div>
                    <div class="sub_title">CORE ADVANTAGES</div>
                    <div class="horizontal_line"></div>
                </div>
                <div class="card_list">
                    <div class="card_list_item" v-for="(item,index) in info.adv" :key="index">
                        <img :src="item.icon">
                        <span class="title">{{ item.title }}</span>
                        <span class="text">{{ item.intro }}</span>
                    </div>
                </div>
                <div class="bg_footer"></div>
            </div>
        </div>
        <div class="project">
            <div class="navtitle">
                <div class="title">项目介绍</div>
                <div class="sub_title">PROJECT INTRODUCTION</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="content">
                <div class="left_btn" @click="changeSlide('left')">←</div>
                <div class="community_list">
                    <div class="community_list_item" ref="communityItem" :style="{ transform: `translateX(${translateX}px)` }" v-for="(item,index) in info.projects" :key="index">
                        <div class="title">{{ item.title }}</div>
                        <span class="sub_title">合作形式：{{ item.mode }}</span>
                        <p>{{ item.intro }}</p>
                        <div class="num">
                            <div class="num_item">
                                <span class="label">房源数：</span>
                                <span class="value">{{ item.housings_num }}套</span>
                            </div>
                            <div class="num_item">
                                <span class="label">出租率：</span>
                                <span class="value">{{ item.rental_rate }}%</span>
                            </div>
                            <div class="num_item">
                                <span class="label">租率同比提高：</span>
                                <span class="value">{{ item.add_rental_rate }}%</span>
                            </div>
                        </div>
                        <div class="product_img">
                            <img v-lazy="item2.src" :title="item2.title" v-for="(item2,index2) in item.images" :key="index2">
                        </div>
                    </div>
                </div>
                <div class="right_btn" @click="changeSlide('right')">→</div>
            </div>
        </div>
        <div class="intention">
            <div class="navtitle">
                <div class="title">填写合作意向</div>
                <div class="sub_title">FILL IN THE COOPERATION INTENTION</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="content">
                <div class="form">
                    <span class="tip">填写前请选择恰当的合作分类</span>
                    <input type="text" placeholder="请填写您所在的城市" v-model="formData.city">
                    <input type="text" placeholder="请填写您的姓名" v-model="formData.true_name">
                    <input type="text" placeholder="请填写您的邮箱" v-model="formData.email">
                    <input type="text" placeholder="请填写您的手机号" v-model="formData.phone">
                    <textarea rows="8" cols="8" placeholder="请填写您的合作内容" v-model="formData.content"></textarea>
                    <div class="btn" @click="submitForm">
                        提交
                    </div>
                    <span class="phone">业务咨询热线：{{ info.business_hotline }}</span>
                </div>
                <div class="select">
                    <span>选择合作事项</span>
                    <div class="btn_list">
                        <div class="btn_list_item" v-for="(item,index) in selectList" :key="index" :class="item.active?'active':''" @click="selectOption(index)">
                            {{ item.label }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading v-if="isLoading"/>
    </div>
</template>
<script>
import { getData,addIntention } from '@/api/cooperation'
export default {
    data() {
        return {
            translateX: 0,
            current: 0,
            formData: {
                city: '', // 所在城市
                true_name: '', //姓名
                email: '', // 邮箱
                phone: '', // 手机号
                content: '', // 合作内容
                coop_items: [] // 合作事项
            },
            selectList: [],
            info: {},
            isLoading: false
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        async initData() {
            try {
                this.isLoading = true
                const { data:res } = await getData()
                this.selectList = res.coops.map(item => {
                    return {
                        label: item,
                        value: item,
                        active: false
                    }
                })
                this.info = res
            }finally {
                this.isLoading = false
            }
        },
        changeSlide(type) {
            let distance = this.$refs.communityItem[0].offsetWidth || 0;
            if(type == 'left') {
                if(!this.current) return
                this.translateX = this.translateX + distance
                this.current = this.current - 1
            }else if(type == 'right') {
                if(this.info.projects.length - this.current <= 1) return
                this.translateX = this.translateX - distance
                this.current = this.current + 1
            }
        },
        selectOption(i) {
            this.selectList.forEach((item,index) => {
                if(i == index) {
                    item.active = !item.active
                }
            })
        },
        async submitForm() {
            this.selectList.filter(item => {
                return item.active
            }).forEach(item => {
                this.formData.coop_items.push(item.value)
            })

            for (const key in this.formData) {
                if(!this.formData[key] || !this.formData[key]?.length) {
                    let list = [
                        { key: 'city',name: '请填写城市'},
                        { key: 'true_name',name: '请填写姓名'},
                        { key: 'email',name: '请填写邮箱'},
                        { key: 'phone',name: '请填写手机号'},
                        { key: 'content',name: '请填写合作内容'},
                        { key: 'coop_items',name: '请选择合作事项'},
                    ]
                    let selectedList = list.filter(item => {
                        return item.key == key
                    })
                    alert(`${selectedList[0].name}`)
                    return
                }
            }
            
            const res = await addIntention(this.formData)
            if(res.code == 200) {
                alert('提交成功')
                this.formData = this.$options.data().formData
                this.selectList.forEach(item => {
                    item.active = false
                })
            }
        }
    }
}
</script>
<style scoped lang="scss">
$width: 1600px;
.container {
    box-sizing: border-box;
    width: 100vw;
    height: auto;
    .banner {
        width: 100%;
        height: 530px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .brand {
        width: $width;
        margin: 0 auto;
        padding: 80px 50px;
        box-sizing: border-box;
        .main {
            p {
                color: #666;
                margin: 60px 0;
                text-indent: 2em;
                line-height: 28px;
                padding: 0 55px;
                box-sizing: border-box;
                font-size: 16px;
            }
            &_center {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 20px;
                flex-wrap: wrap;
                row-gap: 20px;
                .logo_item {
                    width: 22%;
                    height: 240px;
                    border: 2px dashed #eee;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            &_footer {
                margin-top: 20px;
            }
        }
    }
    .advantage {
        width: $width;
        margin: 0 auto;
        padding: 0px 50px 80px 50px;
        box-sizing: border-box;
        .card_list {
            display: flex;
            column-gap: 20px;
            margin-top: 50px;
            &_item {
                flex: 1;
                border: 1px solid #EEEEEE;
                height: 380px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: 28px;
                transition: all 0.3s ease;
                background: #fff;
                &:hover {
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* 移入时的阴影效果 */
                }
                img {
                    width: 112px;
                    height: 112px;
                    object-fit: cover;
                    border-radius: 50%;
                }
                .title {
                    color: #333;
                    font-size: 18px;
                    font-weight: bold;
                    position: relative;
                    &::after {
                        content: '';
                        display: block;
                        width: 30px;
                        height: 4px;
                        background: #FFA71D;
                        position: absolute;
                        bottom: -15px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .text {
                    color: #666;
                    font-size: 16px;
                }
            }
        }
        .bg_footer {
            width: 100vw;
            height: 300px;
            background: #FFA71D;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
        }
    }
    .project {
        width: $width;
        margin: 0 auto;
        padding: 80px 50px 60px 50px;
        box-sizing: border-box;
        background: #fff;
        .content {
            display: flex;
            column-gap: 30px;
            align-items: center;
            margin-top: 60px;
            .community_list {
                flex: 1;
                display: flex;
                overflow: hidden;
                &_item {
                    width: 100%;
                    font-size: 16px;
                    color: #666666;
                    flex-shrink: 0;
                    transition: all 0.5s ease;
                    .title {
                        font-size: 24px;
                        color: #333333;
                        font-weight: bold;
                        position: relative;
                        padding-left: 18px;
                        box-sizing: border-box;
                        &::before {
                            content: '';
                            width: 4px;
                            height: 24px;
                            background: #FFA71D;
                            position: absolute;
                            left: 0px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    .sub_title {
                        display: block;
                        margin: 18px 0;
                    }
                    p {
                        text-indent: 2em;
                    }
                    .num {
                        display: flex;
                        column-gap: 270px;
                        margin: 48px 0;
                        &_item {
                            display: flex;
                            flex-direction: column;
                            row-gap: 10px;
                        }
                        .value {
                            font-size: 32px;
                            color: #FFA71D;
                            font-weight: bold;
                        }
                    }
                    .product_img {
                        position: relative;
                        height: 550px;
                        img {
                            width: 52%;
                            height: 500px;
                            max-height: 500px;
                            object-fit: cover;
                            &:nth-child(1) {
                                position: absolute;
                                left: 0;
                                z-index: 1;
                            }
                            &:nth-child(2) {
                                position: absolute;
                                right: 0;
                                z-index: 2;
                                top: 50px;
                            }
                        }
                    }
                }
            }
            .left_btn,.right_btn {
                margin-top: 250px;
                width: 40px;
                height: 80px;
                border: 1px solid #EEEEEE;
                background: #fff;
                flex-shrink: 0;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #999999;
                -webkit-user-select: none; /* Safari */
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE 10+/Edge */
                user-select: none;
            }
        }
    }
    .intention {
        width: $width;
        margin: 0 auto;
        padding: 0px 50px 80px 50px;
        box-sizing: border-box;
        .content {
            margin-top: 60px;
            position: relative;
            .form {
                width: 1200px;
                height: 650px;
                background: #F5F5F5;
                padding: 40px 60px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                .tip {
                    font-size: 16px;
                    color: #FF6464;
                }
                input,textarea {
                    margin-top: 20px;
                    width: 500px;
                    box-sizing: border-box;
                    color: #999;
                    outline:none;
                    border: none;
                    border-radius: 4px;
                }
                input {
                    height: 40px;
                    padding-left: 16px;
                    padding-right: 16px;
                    font-size: 16px;
                }
                textarea {
                    padding: 16px;
                }
                .btn {
                    width: 120px;
                    height: 40px;
                    background: #FFA71D;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    border-radius: 4px;
                    margin: 30px 0 30px 180px;
                    cursor: pointer;
                }
                .phone {
                    color: #666;
                    font-size: 22px;
                    margin-left: 95px;
                }
            }
            .select {
                width: 800px;
                height: 520px;
                background: url('../../assets/image/hzyx.png') 100% 100% no-repeat;
                background-position: center;
                background-size: cover;
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
                padding: 49px 80px;
                box-sizing: border-box;
                color: #fff;
                span {
                    font-size: 22px;
                    display: block;
                    margin-bottom: 38px;
                }
                .btn_list {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 40px;
                    row-gap: 50px;
                    width: 100%;
                    &_item {
                        cursor: pointer;
                        font-size: 16px;
                        border: 1px solid #FFFFFF;
                        padding: 13px 23px;
                        color: #F5F5F5;
                        transition: all 0.3s ease;
                        &:hover {
                            opacity: 0.9;
                        }
                    }
                    .active {
                        color: #fff;
                        background: #FFA71D;
                        border: 1px solid #FFA71D;
                    }
                }
            }
        }
    }
}
</style>